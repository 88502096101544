import React from 'react';
import AntdSelect from 'antd/lib/select';
import SpriteIconViewer from '@components/Icons/SpriteIconViewer';

const Select = ({
  className = '',
  options = null,
  placeholder = 'Select something',
  getPopupContainer = () => document.getElementById('wanpng-select-container'),
  ...rest
}) => {
  return (
    <div id="wanpng-select-container" className="wanpng-select-container">
      <AntdSelect
        className={`wanpng-select ${className}`}
        dropdownClassName={`wanpng-select-dropdown ${className}`}
        placeholder={placeholder}
        options={options}
        getPopupContainer={getPopupContainer}
        suffixIcon={
          <>
            <SpriteIconViewer
              className="wanpng-select-arrow"
              spriteId="expand_more"
            />
          </>
        }
        {...rest}
      />
    </div>
  );
};

export default Select;
