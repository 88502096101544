import React from 'react';
import Section from '@components/Section';
import InquiryModal from '@components/InquiryModal/FaqInquiryModal';

const ContactUs = () => {
  return (
    <Section id="contact-us" className="faqs-contact-us-section" color="ash">
      <div id="contact" className="faqs-contact-us">
        <div className="title">
          <span className="first-line">Still have a question?</span>
        </div>
        <div className="_description-label">
          If you cannot find answer to your question in our FAQ, you can always
          contact us. We will answer to you shortly.
        </div>
        <InquiryModal />
      </div>
    </Section>
  );
};

export default ContactUs;
