import React from 'react';
import { useLocation } from '@reach/router';
import Header from '@components/Layout/Headers/JobseekersHeader';
import Main from '@components/Layout/Main';
import Footer from '@components/Layout/Footer';
import ComingSoon from '@components/Layout/ComingSoon';
import EmptyState from '@components/EmptyState';
import LiveChatWidgetScript from '@components/LiveChatWidgetScript';
import '@scss/main.scss';
import includes from 'lodash/includes';
const JobseekersLayout = ({
  children,
  className,
  isMobileComingSoon = true,
  isReleased = true,
}) => {
  const { pathname } = useLocation();
  const isMobilePrivacyPolicy = includes(pathname, 'privacy-policy');
  const isTermsAndConditions = includes(pathname, 'terms-and-conditions');
  if (isMobileComingSoon) {
    return (
      <>
        <div className="desktop-only">
          <Header />
          {!isReleased ? (
            <EmptyState />
          ) : (
            <Main className={className}>{children}</Main>
          )}
          <Footer />
        </div>

        <div className="mobile-only">
          <ComingSoon />
        </div>
      </>
    );
  }

  return (
    <>
      <div>
        <Header />
        <Main className={className}>{children}</Main>
        {!isMobilePrivacyPolicy && !isTermsAndConditions && <Footer />}
      </div>
      {!isMobilePrivacyPolicy && !isTermsAndConditions && (
        <LiveChatWidgetScript />
      )}
    </>
  );
};

export default JobseekersLayout;
