import React, { useState, useRef, useEffect } from 'react';
import { useLocation } from '@reach/router';
import axios from 'axios';
import Modal from 'antd/lib/modal';
import Form from 'antd/lib/form';
import Col from 'antd/lib/col';
import Input from 'antd/lib/input';
import Button from 'antd/lib/button';
import Select from '@components/Select';
import notification from 'antd/lib/notification';
import IconCheckedCircle from '@components/Icons/IconCheckedCircle';
import ReCAPTCHA from 'react-google-recaptcha';
import ErrorMessage from '@components/ErrorMessage';
import AgreementMessage from '@components/InquiryAgreement';
import { navigate } from 'gatsby';
import useCurrentPath from '@helpers/hooks/useCurrentPath';

const openNotification = () => {
  notification.success({
    message: `Your inquiry has been sent. We'll be in touch shortly!`,
    placement: 'bottomLeft',
    className: 'success-notification',
    icon: <IconCheckedCircle />,
  });
};

const { TextArea } = Input;

const InquiryModal = ({
  question = 'By inquiring, you agree to be contacted by Wan PNG.',
  visible = false,
  postURL = `${process.env.GATSBY_API_URL}/faq-inquiries`,
}) => {
  const { hash: locationHash, state: locationState } = useLocation();

  const recaptchaRef = useRef();
  const [isModalVisible, setIsModalVisible] = useState(visible);
  const [recaptchaErrorMessage, setRecaptchaErrorMessage] = useState('');
  const [form] = Form.useForm();
  const currentUrlPath = useCurrentPath();
  const clearRecaptchaErrorMessage = () => {
    setRecaptchaErrorMessage('');
  };

  const openModal = () => {
    setIsModalVisible(true);
    document.body.classList.add('modal-open');
  };

  const closeModal = (e) => {
    recaptchaRef.current.reset();
    setIsModalVisible(false);
    clearRecaptchaErrorMessage();
    form.resetFields();
    document.body.classList.remove('modal-open');
    navigate(`${process.env.GATSBY_SITE_URL}${currentUrlPath}#contact`);
  };

  const showModalInquiry = () => {
    openModal();
  };

  const handleCancelButton = () => {
    closeModal();
  };

  const handleSendButton = () => {
    form.submit();
  };

  const handleSubmitForm = async (values) => {
    const recaptchaValue = recaptchaRef.current.getValue();
    const isRecaptchaEmpty = !recaptchaValue;
    if (isRecaptchaEmpty) {
      setRecaptchaErrorMessage('Please verify that you are a human.');
      return;
    }

    await axios
      .post(postURL, {
        user_type: values.user_type,
        given_name: values.first_name,
        family_name: values.last_name,
        contact_number: values.contact_number,
        email: values.email,
        question: !values.question ? question : values.question,
      })
      .then((res) => {
        closeModal();
        openNotification();
      })
      .catch((err) => console.error(err));
  };

  const onRecaptchaChange = (value) => {
    clearRecaptchaErrorMessage();
  };

  useEffect(() => {
    if (locationHash === '#contact-us') {
      openModal();
    }
  }, [locationHash, locationState?.key]);

  return (
    <>
      <Button type="primary" className="primary-btn" onClick={showModalInquiry}>
        Contact us
      </Button>
      <Modal
        className={'inquire-now-container'}
        title="Make an Inquiry"
        visible={isModalVisible}
        width={'100vw'}
        footer={null}
        maskClosable={false}
        onCancel={handleCancelButton}
        wrapClassName="course-inquiry-modal _livechat_overlay"
        centered={true}
      >
        <>
          <section className={'modal-body'}>
            <div className="description">
              Send us a question using the form below and we will get back to
              you promptly.
            </div>

            <Form
              form={form}
              layout={'vertical'}
              onFinish={handleSubmitForm}
              hideRequiredMark={true}
            >
              <div className="form-input-container">
                <Col span={24}>
                  <Form.Item
                    label="User type"
                    name="user_type"
                    rules={[
                      {
                        required: true,
                        message: 'Please select a user type',
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select user type"
                      options={[
                        { value: 'Jobseeker', label: 'Jobseeker' },
                        { value: 'Employer', label: 'Employer' },
                        { value: 'Institution', label: 'Institution' },
                      ]}
                    />
                  </Form.Item>
                </Col>

                <div className="name-container">
                  <div className="first-name-wrapper">
                    <Form.Item
                      label="First name"
                      name="first_name"
                      rules={[
                        {
                          required: true,
                          message: 'Please input your first name.',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </div>
                  <div className="last-name-wrapper">
                    <Form.Item
                      label="Last name"
                      name="last_name"
                      rules={[
                        {
                          required: true,
                          message: 'Please input your last name.',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </div>
                </div>
                <Col span={24}>
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your email.',
                      },
                      {
                        type: 'email',
                        message: 'The input is not valid email.',
                      },
                    ]}
                  >
                    <Input type="email" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="Contact number"
                    name="contact_number"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your contact number.',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="Question"
                    name="question"
                    rules={[
                      {
                        required: true,
                        message: 'Please input a question.',
                      },
                      {
                        max: 500,
                        message: 'Must be at most 500 characters.',
                      },
                    ]}
                  >
                    <TextArea
                      autoSize={{ minRows: 3, maxRows: 4 }}
                      defaultValue={''}
                    />
                  </Form.Item>
                </Col>

                <Col span={24} className="recapatcha-container">
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY}
                    onChange={onRecaptchaChange}
                  />
                  <ErrorMessage message={recaptchaErrorMessage} />
                </Col>

                <AgreementMessage
                  message={
                    'By inquiring, you agree to be contacted by Wan PNG.'
                  }
                />
              </div>
            </Form>
          </section>

          <div className="modal-footer">
            <Button onClick={handleCancelButton} className="cancel-btn">
              Cancel
            </Button>
            <Button
              onClick={handleSendButton}
              type="primary"
              htmlType="submit"
              className="send-btn"
            >
              Send
            </Button>
          </div>
        </>
      </Modal>
    </>
  );
};

export default InquiryModal;
