import { useLocation } from '@reach/router';

const useCurrentPath = () => {
  const location = useLocation();

  const isPathNameSlashOnly = location?.pathname === '/';
  const isPathNameUndefined = !location?.pathname;
  const isPathNameInvalid = isPathNameUndefined || isPathNameSlashOnly;
  if (isPathNameInvalid) return '';

  const URL_CURRENT_PATH = location?.pathname;

  const formatTrailingSlashes = (url) => {
    if (!url) return '';
    if (typeof url !== 'string') return '';
    if (url.endsWith('/')) return url;
    return `${url}/`;
  };

  return formatTrailingSlashes(URL_CURRENT_PATH);
};

export default useCurrentPath;
